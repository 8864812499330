<template>
	<div class="myMain width_1200 margin_auto flex justify_content_sb">
		<div class="myMain_r">
			<Individual></Individual>
		</div>
	</div>
</template>

<script>
import Individual from "./Individual/index";
import Mywz from "./mywz/index";
import { getToken } from "@/utils/auth";
import Publish from "./mywz/publish";
export default {
	name: "",
	components: {
		Individual,
		Mywz,
		Publish,
	},
	data() {
		return {
			wzid: "",
		};
	},
	created() {
		const hasToken = getToken();
		console.log(hasToken);
		if (hasToken) {
		} else {
			this.$router.push({ path: "/" });
			// location.reload();
		}
	},
	methods: {},
};
</script>

<style scoped>
.myMain_r {
	width: 920px;
	opacity: 1;
	padding-top: 20px;
}
.active span {
	color: #0056ff;
}
</style>
